<template>
  <div class="turniere-liste-container">
    <ul class="ul-unstyled turnier-liste">
      <li v-for="turnier in turniereArray" :key="turnier.turnierID" class="turnier-item card ul-unstyled">
        <router-link :to="{ name: getRouterLink(turnier), 
              params: { turnierID: turnier.turnierID }}" class="a-unstyled">
          <div class="list-icon">
            <svg version="1.1" id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <path style="fill:#FF9F00;" d="M407.015,240c-4.849,0-9.609-2.344-12.495-6.68c-4.6-6.899-2.739-16.201,4.16-20.801l69.961-46.641
              c8.364-5.581,13.359-14.912,13.359-24.961V90c0-16.538-13.462-30-30-30s-30,13.462-30,30c0,8.291-6.709,15-15,15s-15-6.709-15-15
              c0-33.091,26.909-60,60-60s60,26.909,60,60v50.918c0,20.112-9.99,38.774-26.719,49.922l-69.961,46.64
              C412.757,239.18,409.871,240,407.015,240z"/>
              <path style="fill:#FDBF00;" d="M104.985,240c-2.856,0-5.742-0.82-8.306-2.52l-69.96-46.64C9.99,179.692,0,161.03,0,140.918V90
              c0-33.091,26.909-60,60-60s60,26.909,60,60c0,8.291-6.709,15-15,15s-15-6.709-15-15c0-16.538-13.462-30-30-30S30,73.462,30,90
              v50.918c0,10.049,4.995,19.38,13.359,24.961l69.961,46.641c6.899,4.6,8.76,13.901,4.16,20.801
              C114.595,237.656,109.834,240,104.985,240z"/>
              <path style="fill:#FFE470;" d="M406,0H106c-8.401,0-15,6.599-15,15v180c0,50.7,27.001,91.199,76.199,114.3
              C194.2,321.899,211,348.5,211,377c0,8.399,6.599,15,15,15h60c8.401,0,15-6.601,15-15v-4.801c0-24.899,13.5-49.999,34.799-62
              C387.1,281.1,421,250.499,421,195V15C421,6.599,414.401,0,406,0z"/>
              <path style="fill:#FFD400;" d="M421,15v180c0,55.499-33.9,86.1-85.201,115.199C314.5,322.2,301,347.3,301,372.199V377
              c0,8.399-6.599,15-15,15h-30V0h150C414.401,0,421,6.599,421,15z"/>
              <path style="fill:#384949;" d="M316,362H196c-24.901,0-45,20.099-45,45v30c0,8.399,6.599,15,15,15h180c8.401,0,15-6.601,15-15v-30
              C361,382.099,340.901,362,316,362z"/>
              <path style="fill:#4A696F;" d="M346,422H166c-24.901,0-45,20.099-45,45v30c0,8.399,6.599,15,15,15h240c8.401,0,15-6.601,15-15v-30
              C391,442.099,370.901,422,346,422z"/>
              <path style="fill:#FDBF00;" d="M345.099,124.799c-1.8-5.7-6.599-9.6-12.299-10.199l-43.801-6.601l-19.499-39.6
              c-2.701-5.099-8.101-7.8-13.5-7.8s-10.8,2.701-13.5,7.8l-19.499,39.6L179.2,114.6c-5.7,0.599-10.499,4.499-12.299,10.199
              c-1.802,5.4-0.3,11.4,3.898,15.3l31.8,30.901l-7.5,43.499c-0.899,5.7,1.201,11.4,6,14.702c4.501,3.3,10.801,3.898,15.601,1.199
              l39.3-20.7l39.3,20.7c4.799,2.699,11.1,2.401,15.601-1.199c4.799-3.301,6.899-9.001,6-14.702l-7.5-43.499l31.8-30.901
              C345.399,136.199,346.901,130.199,345.099,124.799z"/>
              <path style="fill:#FF9F00;" d="M295.3,230.4L256,209.7V60.599c5.4,0,10.8,2.701,13.5,7.8l19.499,39.6L332.8,114.6
              c5.7,0.599,10.499,4.499,12.299,10.199c1.802,5.4,0.3,11.4-3.898,15.3L309.401,171l7.5,43.499c0.899,5.7-1.201,11.4-6,14.702
              C306.4,232.8,300.099,233.099,295.3,230.4z"/>
              <path style="fill:#293939;" d="M361,407v30c0,8.399-6.599,15-15,15h-90v-90h60C340.901,362,361,382.099,361,407z"/>
              <path style="fill:#3E5959;" d="M391,467v30c0,8.399-6.599,15-15,15H256v-90h90C370.901,422,391,442.099,391,467z"/>
            </svg>
          </div>
          <span class="headline">{{turnier.turnierName}}</span>
          <i class="far fa-calendar-check"></i>
          <span class="date">{{dateFormatted(turnier.datum)}}</span>
          <i v-if="showAnzTeams" class="fas fa-users"></i>
          <span v-if="showAnzTeams" class="teams">{{turnier.anzTeams}} {{lang.listTurniere.labelTeam}}</span>
          <button class="link-detail btn-unstyled">
              <i class="fas fa-chevron-left"></i>
          </button>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import store from '@/store/index'
import { VueOfflineMixin } from 'vue-offline'

export default {
  name: 'ListTurniereComponent',
  mixins: [VueOfflineMixin],
  props: {
    turniereArray: {
      type: Array,
      required: true
    },
    showAnzTeams: {
      type: Boolean,
      required: true
    },
    routerLinkTo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      SPIELMODUS: store.getters.getSpielModus,
    }
  },
  methods: {
    dateFormatted(dateString) {
      if (dateString != undefined || dateString != null) {
        let date = dateString.split("-");
        return date[2] + "." + date[1] + "." + date[0];
      } else {
        return "";
      }
    },
    getRouterLink({spielmodus}) {
      if (this.routerLinkTo == "ListSpiele") {
        return spielmodus == this.SPIELMODUS.GRUPPEN_SYSTEM.name ? 'ListGruppenSpiele' : 'ListSingleSpiele'
      } else {
        return this.routerLinkTo;
      }
    }
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    online() {
      return this.isOnline ? true : false;
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/base';

</style>
